<template>
    <div style="position:relative;overflow: hidden" class="login">
        <div>
            <vue-particles
                color="#409EFF"
                :particle0pacity="0.7"
                :particlesNumber="80"
                shapeType="circle"
                :particlesize="4"
                linesColor="#409EFF"
                :lineswidth="1"
                :lineLinked="true"
                :lineopacity="0.4"
                :linesDistance="150"
                :movespeed="3"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
                :style="{height:getWindowHeight}"
            >
            </vue-particles>
        </div>
        <div class="headers">
            <img src="@/assets/logo@2x.png"/>
        </div>
        <div class="suspend">
            <div class="login-wrap">
                <div class="ms-login">
                    <div class="ms-title1">Hi！欢迎登录</div>
                    <div class="ms-title">原链科技官网后台</div>
                    <a-form-model :model="ruleForm" :rules="rules" ref="ruleForm" label-width="0px" class="ms-content">
                        <a-form-model-item prop="username">
                            <div style="display: flex;border-bottom: 1px solid #f6f6f649;">
                                <a-icon type="user" style="color: #409EFF;line-height: 30px;font-size: 24px;"></a-icon>
                                <a-input class="input" v-model="ruleForm.username" placeholder="账号"></a-input>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="password">
                            <div style="display: flex;border-bottom: 1px solid #f6f6f649;">
                                <a-icon type="lock" style="color: #409EFF;line-height: 30px;font-size: 24px"></a-icon>
                                <a-input  type="password" placeholder="密码" v-model="ruleForm.password" @keyup.enter.native="login" class="input"></a-input>
                            </div>
                        </a-form-model-item>
                        <a-form-model-item prop="num">
                            <div style="display: flex;border-bottom: 1px solid #f6f6f649;">
                                <a-icon type="safety" style="color: #409EFF;line-height: 30px;font-size: 24px"></a-icon>
                                <a-input class="input" v-model.trim="ruleForm.num" auto-complete="off" placeholder="请输入验证码"></a-input>
                                <div class="login-code" @click="getCode">
                                    <!--验证码组件-->
                                    <s-identify :identifyCode="identifyCode"></s-identify>
                                </div>
                            </div>
                        </a-form-model-item>
                        <div class="login-btn">
                            <a-button type="primary" @click="login" class="button">登录</a-button>
                        </div>
                    </a-form-model>
                    <div class="footer">
                         <p>技术支持：原链科技</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import SIdentify from '../components/authcode'
import {checkCode} from "@/api/apis";
export default {
    components: { SIdentify },
    data: function () {
        return {
            identifyCode: "",
            code: '',
            ruleForm: {
                username: '',
                password: '',
                num: ''
            },
            rules: {
                username: [{ required: true, message: '请输入账号', trigger: 'blur' }],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                num: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
            },
        };
    },
    mounted() {
        this.getCode()
    },
    computed:{
        getWindowHeight(){
            console.log(window)
            return document.body.clientHeight+'px'
        },
    },
    methods: {
        login(){
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let data = {
                        username: this.ruleForm.username,
                        password: this.ruleForm.password,
                        checkCode: this.ruleForm.num,
                    }

                   if(data.checkCode.toLowerCase() != this.identifyCode.toLowerCase()){
                       this.$message.error('验证码错误')
                       this.getCode()
                   }else if(data.checkCode.toUpperCase() != this.identifyCode.toUpperCase()){
                        this.$message.error('验证码错误')
                        this.getCode()
                    }else if(data.username!='yuanlian' || data.password!= 'yuanlian123'){
                       this.$message.error('账号或密码错误')
                   }else{
                       let userInfo = {
                           username:'原链科技',
                           avatar:require('@/assets/logo-black.png'),
                           id:'我是id'
                       }
                       localStorage.setItem('siteUserInfo',JSON.stringify(userInfo))
                       this.$router.push('/main/bannerOption')
                   }
                } else {
                    return false
                }
            })

        },
        async getCode() {
            let res = await checkCode()
            if(res.code == 200){
                this.identifyCode = res.data
            }else{
                this.$message.error('验证码获取失败！')
                this.getCode()
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.input{
    background-color: transparent;
    border: 0;
    color: #ffffff;
    &:focus{
        box-shadow: unset;
    }
    &:hover{
        background-color: transparent;
    }
}

.login {
    background-image: url('../assets/login-BG.png');
    background-size: 100%;
}

.button {
    height: 48px;
    border-radius: 24px;
}

.suspend {
    position: absolute;
    right: 20%;
    top: 0;
}

.headers {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9999;
    color: #fff;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    font-size: 25px;
    line-height: 8vh;
    img{
        width: 15rem;
        margin-left: 48px;
        margin-top: 40px;
    }
}

.login-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: 100%;
}

.ms-title {
    width: 100%;
    line-height: 30px;
    margin-left: 30px;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 32px;
    color: #FFFFFF;
    text-align: left;
    font-style: normal;
    font-family: PingFangSC-Medium, PingFang SC;
}

.ms-title1 {
    font-family: PingFangSC-Medium, PingFang SC;
    width: 100%;
    line-height: 30px;
    font-size: 18px;
    color: #fff;
    margin-left: 30px;
    margin-top: 30px;
}

.ms-login {
    position: absolute;
    right: -250px;
    top: 150px;
    width: 538px;
    height: 720px;
    padding: 60px;
    box-sizing: border-box;
    background: #000000;
    border-radius: 4px;
    overflow: hidden;
}

.ms-content {
    padding: 30px 30px;
}

.login-btn {
    text-align: center;
}

.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
    margin-top: 30px;
    background-color: #409EFF;
}
.footer {
    width: 100%;
    height: 4vh;
    bottom: 20px;
    left: 0;
    position: absolute;
    text-align: center;
    line-height: 4vh;
    font-size: 14px;
    color: white;
    opacity: .5;
}
</style>

